import React, {useEffect} from "react";

//routes
import Router from './routes';
// theme
import ThemeConfig from './Theme';
import GlobalStyles from './Theme/globalStyles';
import LoadingSpinner from './components/common/LoadingSpinner';

import {useNavigate, useLocation} from "react-router-dom";

//css
import './App.css';


import auth from './Services/auth';

import { useDispatch } from "react-redux";
import {getProfileData} from './actions/profile';
import {logout} from './actions/auth';

import { LoadScript } from '@react-google-maps/api';

const lib = ['geometry', 'drawing', 'places'];
 const key = 'AIzaSyDVWUjE352HjckcdylCZarP7fHyxuNbJ4o'; // PUT GMAP API KEY HERE
//const key = 'AIzaSyDcEEPOExGTY4Ql684WA55DitZfUpmE17M'; // PUT GMAP API KEY HERE

function App() {
  let currentLocation = useLocation();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  function fetchProfileData(){
     dispatch(getProfileData())
     .then((data) => {
       let currentRole = data.roles[0].roleName;
       if(currentRole === 'ROLE_SYS_FACTORY_MANAGER'){
        navigate('/dashboard/devices', { replace: true });
       }
       else if(currentRole === 'ROLE_SYS_LOGISTIC_MANAGER') {
         navigate('/dashboard/logisticDashboard', { replace: true });
       }
       else if(currentRole === 'ROLE_SYS_ORG_ADMIN') {
        navigate('/dashboard/organization', { replace: true });
      }
      else if(currentRole === 'ROLE_SYS_B2B2C_ADMIN' || currentRole === 'ROLE_SYS_B2B_ADMIN') {
        navigate('/dashboard/enterprise', { replace: true });
      } else {
        navigate('/dashboard/app', { replace: true });
       }
     })
      .catch(() => {
        dispatch(logout())
        navigate('/login');
      });
  }
  useEffect(()=>{
    const user = auth.getCurrentUser();
    if(!user){
         dispatch(logout())
         navigate('/login');
    } else {
      fetchProfileData();
    }
  },[])
  return (
    <ThemeConfig>
        <GlobalStyles />
        <LoadScript googleMapsApiKey={key} libraries={lib}>
          <Router />
        </LoadScript>
        <LoadingSpinner />
    </ThemeConfig>
   
  );
}

export default App;
